<template>
  <div class='notification'>
    <layout-header />
    <main class='notification__main base-content-pc'>
      <section class='notification__main__left'>
        <select-bar-setting />
      </section>
      <section class='notification__main__right'>
        <div class='notification__main__right__title'>
          <h3>通知</h3>
          <button-normal
            @emitClick='changeNotification()'
            :disabled='judgeDisabledUpdateButton'
            :loading='isLoading.updateSetting'
          >
            <p>更新する</p>
          </button-normal>
        </div>
        <button-check-box style="justify-content: flex-start;"
          :isActive='isActive.notificationCheckBox'
          @emit='changeCheckBoxValue()'
          text='Donblerからのお知らせを受け取る'
        />
      </section>
    </main>
    <layout-footer />
  </div>
</template>
<script>
import LayoutHeader from '@/components/layouts/LayoutHeader.vue';
import LayoutFooter from '@/components/layouts/LayoutFooter.vue';
import ButtonCheckBox from '@/components/atoms/button/ButtonCheckBox.vue';
import ButtonNormal from '@/components/atoms/button/ButtonNormal.vue';
import SelectBarSetting from '@/components/atoms/select/SelectBarSetting.vue';

export default {
  components: {
    LayoutHeader,
    LayoutFooter,
    ButtonCheckBox,
    ButtonNormal,
    SelectBarSetting,
  },
  data() {
    return {
      isActive: {
        notificationCheckBox: false,
      },
      defaultInfo: {
        clicked: this.$store.state.user.send_email_magazine,
      },
      isLoading: {
        updateSetting: false,
      },
    };
  },
  mounted() {
    // Vuexから設定情報を取得
    const targetSetting = this.$store.state.user.send_email_magazine;
    if (targetSetting === undefined) {
      this.$router.push({ path: '/notfound' });
    } else {
      this.loadNotification();
    }
  },
  computed: {
    judgeDisabledUpdateButton() {
      if (this.defaultInfo.clicked === this.isActive.notificationCheckBox) return true;
      return false;
    },
  },
  methods: {
    showNoticeBar(title, type, text) {
      this.$notify({
        title,
        text,
        type,
        speed: 400,
        group: 'top-right',
      });
    },
    loadNotification() {
      const targetSetting = this.$store.state.user.send_email_magazine;
      this.isActive.notificationCheckBox = targetSetting;
    },
    changeIsActive(target) {
      this.isActive[target] = !this.isActive[target];
    },
    changeCheckBoxValue() {
      // チェックの付け外しをするため
      this.isActive.notificationCheckBox = !this.isActive.notificationCheckBox;
    },
    async changeNotification() {
      this.isLoading.updateSetting = true;
      const actionName = await this.isActive.notificationCheckBox
        ? 'startMailMagazine' : 'endMailMagazine';
      const result = await this.$store.dispatch(actionName);

      this.isLoading.updateSetting = await false;
      if (result === 401) this.$router.push({ name: 'Top' });

      if (result) {
        // アップロード情報初期化
        this.defaultInfo.clicked = this.$store.state.user.send_email_magazine;

        this.showNoticeBar(
          '通知設定の変更を完了しました',
          'success',
        );
      } else {
        this.changeIsActive('notificationCheckBox');
        this.$notify({
          title: '通知設定の変更に失敗しました',
          text: 'しばらく時間を空けてから再度お試しください',
          speed: 400,
          type: 'error',
          group: 'top-right',
        });
      }
    },
  },
};
</script>
<style lang='scss'>
.notification {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.notification__main {
  margin: 0 auto 120px;
  display: flex;
  max-width: 100%;
}

.notification__main__left {
  width: 25%;
  margin-right: 30px;
}

.notification__main__right {
  width: 75%;
  background-color: $color-white;
  padding: 30px;
}

.notification__main__right__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  h3 {
    font-size: 24px;
    font-weight: 600;
  }
}

.notification__main__right__input,
.notification__main__right__introduction {
  h5 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
  }
}

.notification__main__right__input {
  margin-bottom: 5px;
}

.notification__main__right__delete {
  font-size: 12px;
  margin-top: 15px;
  color: $color-dark-gray;
  text-decoration: none;
}

.notification__main__right__delete:hover {
  text-decoration: underline;
}
</style>
